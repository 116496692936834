"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var xhr_1 = require("../src/utils/xhr");
var url_parser_1 = require("../src/utils/url-parser");
var cookie_utils_1 = require("../src/utils/cookie-utils");
var complete_request_reducer_1 = require("../src/widget-v2/reducers/complete-request-reducer");
var tracker_events_1 = require("../src/core/tracker-events");
window.oktag = function (rootUrl, options) {
    if (options === void 0) { options = {}; }
    var UUID_REQUEST_HEADER_KEY = 'xd-oksky-chat-login';
    var TRACKER_REQUEST_HEADER_KEY = 'xd-oksky-tracker-token';
    var TRACKER_REQUEST_HEADER_SESID_KEY = 'xd-oksky-tracker-sesid-token';
    var COMMANDS = {
        page_view: "pageview",
        event: "event",
        page_close: "pageclose",
        w_show: "w-show",
        w_hide: "w-hide",
        w_close: "w-close",
        w_event: "w-event",
    };
    var self = this;
    if (!rootUrl)
        throw "root Url is required";
    self.rootUrl = rootUrl;
    var defaultHeaders = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
    var tenantName = getTenantName(self.rootUrl);
    self.send = function (args) {
        if (args.command !== COMMANDS.page_view) {
            checkCookieAndSend(args);
        }
        else {
            mainSend(args);
        }
    };
    function eventData(args) {
        return {
            command: "event",
            action: args.action,
            category: args.category,
            label: args.label,
            value: args.value,
        };
    }
    function pageData(args) {
        return {
            command: args.command,
            ua: navigator.userAgent,
            p: navigator.platform,
            r: document.referrer,
            l: location.toString(),
            t: new Date().getTime(),
            width: window.top.document.documentElement.offsetWidth,
            height: window.top.document.documentElement.offsetHeight,
            top: window.top.window.pageXOffset,
            left: window.top.window.pageYOffset,
            ptitle: document.title
        };
    }
    function wData(args) {
        return {
            command: args.command,
            ua: navigator.userAgent,
            p: navigator.platform,
            r: document.referrer,
            l: location.toString(),
            t: new Date().getTime(),
            width: window.top.document.documentElement.offsetWidth,
            height: window.top.document.documentElement.offsetHeight,
            top: window.top.window.pageXOffset,
            left: window.top.window.pageYOffset,
            roomId: args.roomId,
            clientId: args.clientId,
            clientCode: args.clientCode,
            ptitle: document.title
        };
    }
    function wEventData(args) {
        return {
            command: args.command,
            action: args.action,
            ua: navigator.userAgent,
            p: navigator.platform,
            r: document.referrer,
            l: location.toString(),
            t: new Date().getTime(),
            width: window.top.document.documentElement.offsetWidth,
            height: window.top.document.documentElement.offsetHeight,
            top: window.top.window.pageXOffset,
            left: window.top.window.pageYOffset,
            roomId: args.roomId,
            clientId: args.clientId,
            clientCode: args.clientCode,
            ptitle: document.title,
            link: args.link,
            messageId: args.messageId,
            videoTime: args.videoTime,
        };
    }
    function mainSend(args) {
        if (args.command === COMMANDS.event) {
            sendTrackerEntry(eventData(args));
        }
        else if (args.command === COMMANDS.page_view) {
            sendTrackerEntry(pageData(args));
        }
        else if (args.command === COMMANDS.page_close) {
            sendTrackerEntryBeacon(pageData(args));
        }
        else if (args.command === COMMANDS.w_show || args.command === COMMANDS.w_hide || args.command === COMMANDS.w_close) {
            sendTrackerEntry(wData(args));
        }
        else if (args.command === COMMANDS.w_event) {
            sendTrackerEntry(wEventData(args));
        }
        else {
            console.error("Event command is not set");
        }
    }
    ;
    function getSisId() {
        var sesId = sessionStorage.getItem(tenantName + "_oksky-tracker-" + getTrackerToken());
        if (!sesId || sesId === null) {
            sesId = "";
        }
        return sesId;
    }
    function getSendUrl() {
        return url_parser_1.urlJoin(self.rootUrl, '/rapi/v1/tracker_entries');
    }
    function sendTrackerEntryBeacon(payload) {
        var _a;
        var url = getSendUrl();
        var jsonData = {
            data: {
                type: "tracker_entries",
                attributes: {
                    payload: payload
                }
            },
            headers: (_a = {},
                _a[TRACKER_REQUEST_HEADER_SESID_KEY] = getSisId(),
                _a[TRACKER_REQUEST_HEADER_KEY] = getTrackerToken(),
                _a)
        };
        var blob = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });
        navigator.sendBeacon(url, blob);
    }
    function sendTrackerEntry(payload) {
        var query = {};
        query.url = getSendUrl();
        query.method = "POST";
        var xdCookieValue = "-";
        query.headers = tslib_1.__assign({}, defaultHeaders, { "Xd-Cookie": xdCookieValue });
        var xAccessToken = "";
        if (document.cookie) {
            var xAccessTokenHash = document.cookie.split("; ").filter(function (s) { return s.match(/^xd-access_token/); }).map(function (s) { return s.replace(/^xd-access_token=/, ''); });
            xAccessToken = xAccessTokenHash[0] || "";
        }
        query.headers[UUID_REQUEST_HEADER_KEY] = cookie_utils_1.getCookie("xd-" + tenantName + "_oksky-chat_login");
        query.headers[TRACKER_REQUEST_HEADER_KEY] = getTrackerToken();
        query.headers[TRACKER_REQUEST_HEADER_SESID_KEY] = getSisId();
        query.headers["X-Access-Token"] = xAccessToken;
        query.data = JSON.stringify({
            data: {
                type: "tracker_entries",
                attributes: {
                    payload: payload
                }
            }
        });
        query.onComplete = function (event) {
            if (event.status != 200 && event.status != 201) {
                console.error("Error " + event.status + ": " + event.statusText);
            }
            else {
                if (payload.command === "pageview") {
                    var setCookiesList = complete_request_reducer_1.parseXdSetCookie(event);
                    var filteredValues = setCookiesList.filter(function (value) { return value.startsWith(tenantName + "_oksky-tracker-token="); });
                    cookie_utils_1.setCookies(filteredValues, "xd-");
                    var filteredSesIdValues = setCookiesList.filter(function (value) { return value.startsWith(tenantName + "_oksky-tracker-ses-id="); });
                    var sesId = "";
                    for (var i = 0; i < filteredSesIdValues.length; ++i) {
                        var cookie = filteredSesIdValues[i];
                        var splitted = cookie.split("=");
                        if (splitted.length > 1) {
                            sesId = splitted[1].split(';')[0];
                        }
                    }
                    var allId = getTrackerToken();
                    sessionStorage.setItem(tenantName + "_oksky-tracker-" + allId, sesId);
                    window.removeEventListener('unload', beforeUnloadHandler);
                    window.addEventListener('unload', beforeUnloadHandler, { once: true });
                    document.removeEventListener(tracker_events_1.WIDGET_V2_TRACKER_CHAT_OPEN, chatOpenTracker);
                    document.addEventListener(tracker_events_1.WIDGET_V2_TRACKER_CHAT_OPEN, chatOpenTracker, false);
                    document.removeEventListener(tracker_events_1.WIDGET_V2_TRACKER_CHAT_CLOSE, chatCloseTracker);
                    document.addEventListener(tracker_events_1.WIDGET_V2_TRACKER_CHAT_CLOSE, chatCloseTracker, false);
                    document.removeEventListener(tracker_events_1.WIDGET_V2_TRACKER_CHAT_HIDDEN, chatHiddenTracker);
                    document.addEventListener(tracker_events_1.WIDGET_V2_TRACKER_CHAT_HIDDEN, chatHiddenTracker, { once: true });
                    document.removeEventListener(tracker_events_1.WIDGET_V2_TRACKER_CHAT_EVENT, chatEventTracker);
                    document.addEventListener(tracker_events_1.WIDGET_V2_TRACKER_CHAT_EVENT, chatEventTracker, false);
                    var hiddenInput = document.createElement('input');
                    hiddenInput.setAttribute('type', 'hidden');
                    hiddenInput.setAttribute('name', 'pv-tracker');
                    hiddenInput.setAttribute('id', 'pv-tracker');
                    hiddenInput.value = "1";
                    document.body.appendChild(hiddenInput);
                }
            }
        };
        xhr_1.xhr(query);
    }
    function getTenantName(url) {
        var domain = (new URL(url)).hostname;
        return domain.split('.')[0];
    }
    function chatOpenTracker(event) {
        self.send({ command: "w-show", roomId: event.detail.roomId, clientId: event.detail.clientId, clientCode: event.detail.clientCode });
    }
    function chatCloseTracker(event) {
        self.send({ command: "w-close", roomId: event.detail.roomId, clientId: event.detail.clientId, clientCode: event.detail.clientCode });
    }
    function chatHiddenTracker(event) {
        self.send({ command: "w-hide", roomId: event.detail.roomId, clientId: event.detail.clientId, clientCode: event.detail.clientCode });
    }
    function chatEventTracker(event) {
        var messageId = null;
        if (event.detail.messageId && event.detail.messageId !== null && event.detail.messageId !== "") {
            messageId = Number(event.detail.messageId);
        }
        self.send({ command: "w-event",
            action: event.detail.action,
            link: event.detail.link,
            messageId: messageId,
            roomId: event.detail.roomId,
            clientId: event.detail.clientId,
            clientCode: event.detail.clientCode,
            videoTime: event.detail.videoTime });
    }
    function beforeUnloadHandler(event) {
        self.send({ command: "pageclose" });
    }
    function getTrackerToken() {
        return cookie_utils_1.getCookie("xd-" + tenantName + "_oksky-tracker-token");
    }
    function checkTrackerToken() {
        var cookieValue = getTrackerToken();
        if (cookieValue !== null && cookieValue !== "") {
            return true;
        }
        return false;
    }
    function checkCookieAndSend(args) {
        if (checkTrackerToken()) {
            mainSend(args);
            return;
        }
        startInterval(args);
    }
    function startInterval(args) {
        var interval = null;
        var okFlg = false;
        interval = setInterval(function () {
            if (okFlg)
                return;
            if (checkTrackerToken()) {
                okFlg = true;
                clearInterval(interval);
                interval = null;
                mainSend(args);
            }
        }, 200);
    }
};
